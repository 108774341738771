section.invite-partner {
  padding: 16px;
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 10px;

    .wrapper {
      width: 35%;
      min-width: 350px;
      margin-bottom: 40px;

      .title {
        font-size: 1.4em;
        font-weight: 400;
        padding-bottom: 30px;
      }

      .padded {
        padding: 20px 0;
      }
    }
  }
}
