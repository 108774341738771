.bottom {
  .label_container {
    display: flex;
    justify-content: space-between;

    .label {
      text-transform: capitalize;
      font-size: 0.9em;
      display: inline-block;
      padding: 5px 10px;
      background-color: #57d9a3;
      color: #fff;
      border-radius: 3px;
      margin-bottom: 10px;
    }

    .content {
      padding-top: 10px;
      border-top: 1px solid #eee;
      text-align: right;
    }

    .inactive {
      background-color: #fc5739 !important;
    }
  }
}

.group-genders {
  height: 100% !important;

  .ui.grid {
    height: 100% !important;
  }

  .row {
    padding-bottom: unset !important;
  }
}
