@import '../../assets/styles/colors.scss';

section.login.select__org {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: $color-primary;

  .container {
    width: 30vw;
    max-width: 500px;
    min-width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img {
      width: 38%;
      margin-bottom: 40px;
    }

    .header__text {
      text-align: center;
    }

    .organization__list {
      max-height: 400px;
      min-height: 100px;
      padding: 0.2rem;
      overflow-x: hidden;
      overflow-y: scroll;
      .segment {
        display: flex;
        align-items: center;
        padding: 0.6rem;

        img {
          height: 25px;
          width: 25px;
          margin-bottom: 0;
          margin-right: 0.8rem;
        }
        span {
          font-weight: 600;
        }
      }
      &__item {
        display: flex;
        width: 100%;
        align-items: center;
      }
    }

    .org__status {
      width: 10px;
      height: 10px;
      border-radius: 5px;
      display: inline-block;
      margin-left: auto;
      margin-right: 0.5rem;

      &.active {
        background-color: #57d9a3 !important;
      }

      &.inactive {
        background-color: #767170 !important;
      }
    }

    .forgot {
      align-self: stretch;
      margin-top: 15px;

      a {
        color: #fff;
        font-weight: 300;
      }
    }
  }
}
