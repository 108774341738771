.organizations-overview {
  padding-right: 16px;
  .line-chart__container {
    width: 100%;
    margin: 0px;
  }
  .barchart__container {
    width: 100%;
    margin: 0px;
    height: 220px;
  }
}

.organizations-genders {
  .row {
    padding-bottom: unset !important;
  }
  .barchart__count {
    margin: 5px 0 !important;
  }
}
