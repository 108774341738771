@import 'assets/styles/_variables';

.communication-tab {
  padding-top: 8px;

  .ui.pointing.secondary.menu > a {
    padding-bottom: 24px;
  }

  .ui.segment.active.tab {
    padding: 0px;
    border: none;
    border-radius: 0px;
    overflow-y: auto;
    max-height: $semanticActiveTab;
  }

  .save-dash-toolbar-header {
    border: none;
  }

  .scroll-wrapper {
    max-height: $scrollWrapperaxHeigh;
  }
}
