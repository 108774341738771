.partners {
  padding: 16px;
}

tr:hover {
  cursor: pointer;
  background-color: #f8f8f8;
}

.partner-name {
  display: flex;
  align-items: center;

  .thumbnail {
    margin-right: 15px;
  }
}
