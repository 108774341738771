.group-transactions__lists {
  padding-top: 20px;
  padding: 16px 16px 2px;
  overflow: hidden;

  .groups-requests-table {
    button,
    .ui,
    .button {
      margin: 0;
      background: none;
      &:hover,
      &:focus {
        background: none;
      }
    }
    padding-top: 20px;
    padding: 16px 16px 2px;
    table {
      tbody {
        display: block;
        height: calc(100vh - 400px);
        overflow-y: auto;
        overflow-x: hidden;

        td {
          width: auto;
        }

        td:last-child {
          width: 3%;
        }
      }
      th:first-child,
      td:first-child {
        width: 10px;
      }

      thead {
        display: table;
        width: 97%;
        table-layout: fixed;
        position: relative;
      }
      tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
        position: relative;
      }
      .wallet__transactions--status {
        margin-top: 13px;
      }

      td:first-child {
        padding-bottom: 20px;
      }
      &.isActive ~ .fold {
        display: table;
      }
      .state {
        color: #888;
        margin-top: 0.4rem;
        cursor: pointer;
        padding-left: 5px;
        &::before {
          content: '';
          width: 0.6em;
          height: 0.6em;
          display: inline-block;
          border-right: 0.23em solid currentColor;
          border-bottom: 0.23em solid currentColor;
          transform: rotate(-45deg);
        }
        &.open ~ .fold {
          display: table;
        }
        &.open::before {
          content: '';
          width: 0.6em;
          height: 0.6em;
          display: inline-block;
          border-right: 0.23em solid currentColor;
          border-bottom: 0.23em solid currentColor;
          transform: rotate(45deg);
        }
        &:focus {
          outline: none;
        }
      }
      .fold {
        display: none;
      }
      .view {
        display: table;
        font-size: 13px;
        td:nth-child(2) {
          padding-left: 40px;
        }
      }
    }
  }

  .reason-popup-bold {
    font-weight: bolder !important;
    font-size: 29px;
  }

  .popup_decline {
    color: red !important;
  }
  .popup_approve {
    color: green !important;
  }

  .action-icon:hover {
    cursor: pointer;
  }

  .view-reason {
    color: #4c9aff;
  }

  .view-reason:hover {
    cursor: pointer;
  }

  .request-status {
    font-size: 12px;
    margin-bottom: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.status-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  color: #fff;
  font-size: 1.1em;
  background-color: #4d99ff;
  margin-right: 0 !important;
  outline: none !important;
}

.loan-info-cards {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 25px 0 30px 0;
}

.loan-info-cards-items {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.loan-info-cards-item {
  display: flex;
  flex-direction: row;
}
