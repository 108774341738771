.all-users-tab {
  padding-top: 9px;

  .ui.pointing.secondary.menu > a {
    padding-bottom: 22px !important;
  }

  .ui.segment.active.tab {
    padding: 0px !important;
    border: none !important;
    border-radius: 0px;
  }

  .save-dash-toolbar-header {
    border: none !important;
  }

  .scroll-wrapper {
    max-height: calc(100vh - 220px);
  }
}
