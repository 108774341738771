.support {
  h1 {
    padding: 20px 0;
  }

  &__form {
    width: 100%;
    display: flex;

    form {
      width: 50%;

      label {
        font-size: 1em !important;
        padding-bottom: 15px;
      }

      .input {
        margin-bottom: 35px !important;
      }

      textarea {
        min-height: 250px;
        margin-bottom: 10px !important;
        resize: none !important;
      }
    }
  }
}
