.single_organization_overview_header {
  padding: 10px 0 10px 15px;
  .header_right_component {
    display: flex;
    align-items: center;
  }
}
.group-details {
  .details {
    width: 100%;

    .detail {
      display: flex;
      justify-content: space-between;
    }
  }
}
