@import './colors.scss';

#titlebar,
.pannels-column {
  min-width: 1000px;
}

//Sidebar
$sidebar-menu-margin: 14px;
$sidebar-width: $sidebar-menu-margin * 5;
$sidebar-menu-size: $sidebar-width - ($sidebar-menu-margin * 2);
$sidebar-menu-radius: 4px;

::-webkit-scrollbar {
  width: 6px !important;
  background-color: inherit !important;
  border-right: solid 1px $border-color !important;

  &:hover {
    background-color: $border-color !important;
  }
}

::-webkit-scrollbar-thumb {
  border-radius: 0px !important;
  background-color: $border-color !important;
  border-left: solid 1px $border-color !important;

  &:hover {
    background-color: $border-color-dark !important;
  }
}

::-webkit-scrollbar-track {
  background-color: transparent !important;
}

* {
  font-family: Helvetica;
  text-rendering: geometricPrecision !important;

  a {
    &:focus {
      outline: none !important;
    }
  }
}

body {
  background-color: $color-primary;
  overflow: hidden;
  padding: 0;
  margin: 0;

  .padder {
    display: inline-block;
    width: 16px;
  }

  .padding {
    padding: 16px;

    & > .header:first-child:not(.ui) {
      margin-right: -16px;
      padding-right: 16px;
    }

    & > .my-list {
      margin-left: -16px;
      margin-right: -16px;
      padding-top: 24px !important;
    }
  }
}

.flex-1 {
  flex: 1 !important;
}

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-center {
  justify-content: center;
}

.flex-between {
  justify-content: space-between;
}

.flex-around {
  justify-content: space-around;
}

.flex-start {
  align-items: flex-start;
}

.flex-end {
  align-items: flex-end;
}

.flex-center-vertical {
  align-items: center;
}

.flex-center-horizontal {
  justify-content: center;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.flex-grow {
  flex-grow: 1;
}

.flex-shrink {
  flex-shrink: 1;
}

.attach-left {
  align-items: flex-start;
}

.cards {
  margin-left: 2px !important;
  height: 150px;
  align-items: center;
  justify-content: center;
  color: map-get($i-color-list, blues) !important;

  & div:first-child {
    font-size: 12px;
  }

  & div:nth-child(2) {
    padding-top: 10px;
    font-size: 32px;
    font-weight: 900;
  }
}

.cardss {
  height: 150px;
  background-color: map-get($i-color-list, blues) !important;
  color: $color-light;
  align-items: center;
  justify-content: center;

  & div:first-child {
    font-size: 12px;
  }

  & div:nth-child(2) {
    padding-top: 10px;
    font-size: 32px;
    font-weight: 900;
  }
}

.aside-agents {
  width: 530px;
  // margin: 75px 40px 0 32px;
  height: calc(100vh - 128px) !important;
  display: block;
  // margin: 75px 40px 0 32px;
  position: relative;
  overflow: hidden;
  overflow-y: auto;

  .in-aside-agents {
    margin: 75px 40px 0 32px;
  }
}

.aside-saving-groups {
  width: 400px;
  max-height: calc(100vh - 128px) !important;
  display: block;
  // margin: 75px 40px 0 32px;
  overflow: hidden;
  overflow-y: auto;
  position: relative;

  // margin: auto;
  // & div:first-child {
  // }
  .in-aside-savings-groups {
    // margin: 0 30px !important;
    margin: 75px 40px 0 32px !important;
  }
}

.aside-saving-groups-members {
  width: 400px;
  height: calc(100vh - 128px) !important;
  display: block;
  // margin: 75px 40px 0 32px;
  position: relative;
  overflow: hidden;
  overflow-y: auto;

  // margin: auto;
  // & div:first-child {
  //     margin: ;
  // }
  .in-aside-savings-groups-members {
    // margin: 0 30px !important;
    margin: 75px 40px 0 32px;
  }
}

.profile-slight-detail {
  padding-bottom: 40px;
}

.boxx-overview {
  display: flex;
  flex-direction: row;
  color: $color-light-dark;
  text-align: center;

  & div:first-child {
    margin: 0 30px 0 15px;
  }

  & div {
    // margin: 0 15px 0 15px;
    & .boxx-value {
      // text-align: center;
      font-size: 32px;
      font-weight: 900;
      margin: 20px 0px 20px 0px;
    }

    & .boxx-title {
      font-size: 16px;
      margin: 10px 0px 10px 0px;
    }
  }
}

.boxxx-overview {
  display: flex;
  flex-direction: row;
  color: $color-light-dark;
  margin-right: -10px;

  & div:first-child {
    margin: 0 30px 0 15px;
  }

  & div {
    // margin: 0 15px 0 15px;
    & .boxx-value {
      // text-align: center;
      font-size: 32px;
      font-weight: 900;
      margin: 20px 0px 20px 0px;
    }

    & .boxxx-title {
      font-size: 16px;
      margin: 10px 0px 10px 0px;
      font-weight: 600;
    }
  }
}

.row-reverse {
  flex-direction: row-reverse !important;
}

.column-reverse {
  flex-direction: column-reverse !important;
}

.areaError {
  display: flex;
  flex-direction: row;

  span:first-child {
    flex: 1;
  }

  span:last-child {
    cursor: pointer;
    font-weight: 600;
    text-transform: uppercase;
  }
}

.my-badge {
  display: inline-block;
  padding: 0px 6px;
  font-size: 9px;
  height: 11px;
  line-height: 11px;
  background-color: $color-danger;
  color: $color-light;
  text-transform: uppercase;
  align-self: center;
  margin-left: 8px;
  border-radius: 6px;
}

.some-sticky-table-stuff {
  .my-data-table {
    overflow: unset !important;
  }
}

.header-mod {
  color: #393d58 !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 17px !important;
}

.steps-header {
  color: #393d58 !important;
}

.my-badge-status-paid:before {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin: auto 5px;
  background: $color-new-green;
}

.my-badge-status-unpaid:before {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin: auto 5px;
  background: $color-new-red;
}

.my-badge-status-yellow:before {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin: auto 5px;
  background: $color-new-yellow;
}

.pannels-scroll,
.pannels-row {
  display: flex;
  flex: 1;
  flex-direction: row;

  &:not(.the-root) {
    max-width: calc(100vw - #{$sidebar-width});
  }

  overflow: auto;
}

.pannels-column {
  display: flex;
  flex: 1;
  flex-direction: column;

  .pannel-scroll-inside {
    height: calc(100vh - 128px);
    overflow: hidden;
    overflow-y: auto;
    width: 100%;

    .my-data-table {
      overflow: unset !important;

      .padding {
        .header {
          position: -webkit-sticky !important;
          position: -moz-sticky !important;
          position: -ms-sticky !important;
          position: -o-sticky !important;
          position: sticky !important;
          top: 0 !important;
          background-color: $color-light !important;
          z-index: 3 !important;
        }

        .data-table {
          overflow: unset !important;

          table {
            thead {
              tr {
                th {
                  position: -webkit-sticky !important;
                  position: -moz-sticky !important;
                  position: -ms-sticky !important;
                  position: -o-sticky !important;
                  position: sticky !important;
                  top: 50px !important;
                  background-color: rgba(
                    $color-light,
                    0.95
                  ) !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

.pannel-scroll {
  display: block;
  flex: 1;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 32px;
  position: relative;

  &:after {
    content: ' ';
    display: block;
    position: relative;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: rgba($border-color, 0.1);
    left: 0;
    right: 0;
    margin: 0px auto;
  }

  & > .padding {
    & > .header:not(.ui) {
      position: -webkit-sticky;
      position: -moz-sticky;
      position: -ms-sticky;
      position: -o-sticky;
      position: sticky;
      top: 0;
      background-color: $color-light;
      z-index: 3;
    }
  }
}

.abrev-userr {
  display: flex;
  background-color: map-get($i-color-list, red-mod) !important;
  border-color: map-get($i-color-list, red-mod) !important;
  width: 150px;
  height: 150px;
  border-radius: 5px;
  position: relative;

  & p {
    margin: auto !important;
    text-align: center;
    font-size: 48px;
    color: $color-light;
  }

  & span {
    // bottom: 0 !important;
    vertical-align: bottom;
    position: absolute;
    bottom: 10%;
    right: 5%;

    & .opacity-ic {
      opacity: 0.24 !important;
    }
  }
}

.fsp-inside {
  margin: auto;
}

.paid-amount-fsp {
  display: flex;
  margin: 10px auto 40px auto;
  height: 200px;
  width: 300px;
  box-shadow: 0 2px 3px 0 $color-new-green;
  border-radius: 5px;
  border: 1px solid $color-new-green;
  text-align: center;

  .paid-amount-fsp-text {
    font-size: 32px;
    color: $color-new-green;
    font-weight: 900;
    margin: 12px 0 12px 0;
  }
}

.unpaid-amount-fsp {
  display: flex;
  margin: auto;
  height: 200px;
  width: 300px;
  box-shadow: 0 2px 3px 0 $color-new-red;
  border-radius: 5px;
  border: 1px solid $color-new-red;
  text-align: center;

  .unpaid-amount-fsp-text {
    font-size: 32px;
    color: $color-new-red;
    font-weight: 900;
    margin: 12px 0 12px 0;
  }
}

.pointer {
  cursor: pointer;
}

.rwf {
  .ui.input {
    input {
      padding-right: 32px;
    }

    &:after {
      content: 'RWF';
      position: absolute;
      display: block;
      width: 32px;
      right: 0px;
      font-size: 10px;
      font-weight: 600;
      height: 16px;
      line-height: 16px;
      top: 0;
      bottom: 0;
      margin: auto 0;
    }
  }
}

form {
  .field {
    label {
      font-weight: 400 !important;
    }
  }
}

#app {
  display: flex;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  flex-direction: row;

  #first-login {
    display: flex;
    position: fixed;
    z-index: 9999;
    width: 100vw;
    height: 100vh;
    top: 0px;
    left: 0px;
    background-color: $color-light;

    & > .pannel-scroll > .padding {
      max-width: 420px;
      position: relative;
      left: 0;
      right: 0;
      margin: 0px auto;
    }
  }

  &.public-forms {
    align-items: center;
    justify-content: center;

    div {
      a {
        color: $color-light;

        &:hover {
          color: rgba($color-light, 0.5);
        }
      }
    }
  }

  .abrev {
    display: inline-block;
    height: $sidebar-menu-size;
    width: $sidebar-menu-size;
    background-color: $color-primary-dark;
    border-radius: calc(#{$sidebar-menu-size}/ 1.6);
    text-align: center;
    line-height: calc(#{$sidebar-menu-size} - 4px);
    color: rgba($color-light, 0.75);
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 800;
    border: solid 2px $color-primary-dark;

    &.menu-item {
      background-color: $menu-bg;
      margin-top: $sidebar-menu-margin;
      position: relative;

      img {
        height: calc(#{$sidebar-menu-size}/ 2);
        width: calc(#{$sidebar-menu-size}/ 2);
        display: inline-block;
        line-height: calc(#{$sidebar-menu-size}/ 2);
      }

      &:hover {
        background-color: $color-primary-dark;
      }

      &.active:after {
        content: '';
        display: block;
        height: $sidebar-menu-margin;
        width: $sidebar-menu-margin;
        position: absolute;
        background: $color-light;
        border: solid $color-primary calc(#{$sidebar-menu-margin} / 4);
        border-radius: calc(#{$sidebar-menu-margin} / 1.5);
        top: 0;
        bottom: 0;
        margin: auto 0px;
        right: -#{$sidebar-menu-margin + 2px};
      }
    }

    .dropdown.icon {
      display: none !important;
    }
  }

  #sidebar {
    width: $sidebar-width;
    background-color: $color-primary;
    display: flex;
    flex-direction: column;
    padding: $sidebar-menu-margin;

    .abrev {
      border-radius: 6px;
      border-color: transparent;
    }
  }

  #content {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: calc(100vw - #{$sidebar-width});

    #titlebar {
      height: $sidebar-width;
      background-color: $color-grey;
      display: flex;
      flex-direction: row;
      padding: $sidebar-menu-margin;
      border-bottom: solid 1px $border-color;

      .home-link {
        display: block;
        height: $sidebar-menu-size;
        padding-left: $sidebar-menu-margin;
        padding-right: $sidebar-menu-margin;
        margin-left: calc(#{$sidebar-menu-margin} * -1);
        margin-right: $sidebar-menu-margin;
        line-height: $sidebar-menu-size;
        text-align: center;
        border-right: solid 1px $border-color;

        &:hover {
          background-color: $border-color;
        }

        &:hover,
        &.active {
          img {
            opacity: 1;
          }
        }

        img {
          opacity: 0.35;
          display: inline-block;
          width: calc(#{$sidebar-menu-size}* 0.65);
        }
      }

      #top-selector {
        & > h1,
        h2 {
          display: flex;
          font-size: 28px;
          flex-direction: row;
          align-items: center;
          margin-top: 0px;
          line-height: $sidebar-menu-size;
          color: $border-color-dark;
          cursor: pointer;

          span {
            display: inline-block;
            max-width: 240px;
            line-height: $sidebar-menu-size;
            height: $sidebar-menu-size;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 0.8em;
          }

          .icon {
            margin-right: 0px;
            margin-left: calc(#{$sidebar-menu-margin}/ 2) !important;
            font-size: $sidebar-menu-margin * 1.5;
            height: $sidebar-menu-margin * 1.5;
            line-height: $sidebar-menu-margin * 1.5;
          }

          &:hover {
            color: $color-primary;
          }
        }

        &.selected {
          h1,
          h2 {
            color: $color-primary;
          }
        }
      }

      & > .ui.button {
        padding-top: 0px;
        padding-bottom: 0px;
        line-height: $sidebar-menu-size;
      }
    }

    #pages {
      display: flex;
      flex-direction: row;
      flex: 1;
      background-color: $color-light;
      height: calc(100vh - #{$sidebar-width});
      min-height: calc(100vh - #{$sidebar-width});
      max-height: calc(100vh - #{$sidebar-width});

      form {
        &:not(.fluid) {
          max-width: 400px;
        }
      }

      .sign-out-page {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: 1;
      }

      .toolbar {
        display: flex;
        flex-direction: row;
        height: calc(#{$sidebar-width} * 0.6);

        & > * {
          height: calc(#{$sidebar-width} * 0.6);
          border-bottom: solid 2px $border-color;

          &.active {
            font-weight: 600 !important;
            border-bottom-color: $color-primary;
          }
        }

        .item {
          line-height: calc((#{$sidebar-width} * 0.6) - 2px);
          font-size: 16px;
          padding: 0px 16px;
          font-weight: 300;
          cursor: pointer;
          color: $color-primary-dark;

          &:hover {
            border-bottom-color: $color-primary;
          }
        }
      }

      .header:not(.ui) {
        display: flex;
        height: calc(#{$sidebar-width} * 0.75);
        flex-direction: row;
        font-weight: 600;
        color: $color-primary-dark;
        align-items: flex-end;
        padding-bottom: 16px;
        margin-bottom: 16px;
        border-bottom: solid 1px $border-color;

        .title {
          font-size: 24px;
          line-height: 26px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          flex: 1;
        }

        .back {
          line-height: 26px;
          height: 26px;
          padding-right: 32px;
          cursor: pointer;
        }

        .backk {
          line-height: 26px;
          height: 26px;
          padding-right: 32px;
          margin-left: -16px;
          cursor: pointer;
        }

        .ui.input,
        .ui.button {
          height: 32px;
          min-height: 32px;
          max-height: 32px;
          margin-right: 0px;
          margin-left: 0px;
        }

        .ui.button {
          padding-top: 0px !important;
          padding-bottom: 0px !important;

          &,
          & > * {
            &:not(.label) {
              height: 30px !important;
              line-height: 30px !important;
            }

            &.label {
              height: 32px !important;
              line-height: 32px !important;
            }

            &.icon {
              padding-top: 2px;
            }
          }
        }

        & > .ui.button {
          margin-left: 1em;
        }
      }

      .nameFilter,
      .typeFilter {
        display: inline-block;
        height: 18px;
        margin-left: 8px;
        line-height: 18px;
        font-size: 12px;
        font-weight: 600;
        border-radius: 4px;
        background-color: map-get($i-color-list, green);

        &:not(.nameFilter) {
          background-color: map-get($i-color-list, orange);
        }

        color: $color-light;
        padding: 0px 8px;
      }

      .ui.input,
      .ui.button {
        height: 32px;
        min-height: 32px;
        max-height: 32px;
        margin-right: 0px;
        margin-left: 0px;
      }

      .ui.button {
        padding-top: 0px !important;
        padding-bottom: 0px !important;

        &,
        & > * {
          &:not(.label) {
            height: 30px !important;
            line-height: 30px !important;
          }

          &.label {
            height: 32px !important;
            line-height: 32px !important;
          }

          &.icon {
            padding-top: 2px;
          }
        }
      }

      & > .ui.button {
        margin-left: 1em;
      }
    }

    .nameFilter,
    .typeFilter {
      display: inline-block;
      height: 18px;
      margin-left: 8px;
      line-height: 18px;
      font-size: 12px;
      font-weight: 600;
      border-radius: 4px;
      background-color: map-get($i-color-list, green);

      &:not(.nameFilter) {
        background-color: map-get($i-color-list, orange);
      }

      color: $color-light;
      padding: 0px 8px;
    }
  }

  .sidebar {
    .header:not(.ui) {
      border-bottom-color: transparent;

      .title {
        font-size: 14px;
        line-height: 16px;
      }
    }

    a {
      display: block;
      margin-top: 16px;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $border-color-dark;

      &:hover {
        color: $color-primary;
      }

      &.active {
        color: $color-primary-dark;
        font-weight: 600;
      }
    }
  }

  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 16px;

    &:not(.user-deactivated) {
      @for $i from 1 through 200 {
        &:nth-child(#{$i}) {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding-left: 16px;

          .abrev {
            $the-bg-color: nth($colors, random(length($colors)));
            background-color: $the-bg-color !important;
            border-color: $the-bg-color !important;
          }
        }
      }
    }
  }

  .my-list {
    display: block;
    padding: 8px 0px;

    &.activity {
      .item {
        .title {
          font-weight: 600 !important;
          font-size: 14px !important;
        }

        .description {
          font-weight: 400 !important;
          font-size: 15px !important;
          color: $color-primary-dark !important;

          span.name,
          span.amount {
            color: map-get($i-color-list, blue);
          }
        }
      }
    }

    .item {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-left: 16px;

      &:not(.user-deactivated) {
        @for $i from 1 through 200 {
          &:nth-child(#{$i}) {
            .abrev {
              $the-bg-color: nth($colors, random(length($colors)));
              background-color: $the-bg-color !important;
              border-color: $the-bg-color !important;
            }
          }

          @at-root {
            tbody tr:nth-child(#{$i}) {
              .abrev {
                $the-bg-color: nth($colors, random(length($colors)));
                background-color: $the-bg-color !important;
                border-color: $the-bg-color !important;
              }
            }
          }
        }
      }

      &:not(div):hover {
        .abrev {
          color: rgba($color-light, 1);
        }

        .title,
        .description,
        .right {
          color: $color-primary-dark;
        }
      }

      .abrev {
        margin-right: 16px;
        height: 40px;
        width: 40px;
        line-height: 36px;
        font-weight: 400;
        font-size: 16px;
      }

      .body {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        min-height: calc(#{$sidebar-menu-size} + 16px);
        border-bottom: solid 1px $border-color;
        padding: 8px 16px 8px 0px;

        .title {
          font-size: 18px;
          font-weight: 200;
          line-height: 20px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: $color-primary-dark;
        }

        .description {
          font-size: 12px;
          font-weight: 200;
          line-height: 14px;
          color: $border-color-dark;
        }
      }

      .right {
        color: $color-primary-dark;
      }
    }

    .abrev {
      margin-right: 16px;
      height: 40px;
      width: 40px;
      line-height: 36px;
      font-weight: 400;
      font-size: 16px;
    }

    .body {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      min-height: calc(#{$sidebar-menu-size} + 16px);
      border-bottom: solid 1px $border-color;
      padding: 8px 16px 8px 0px;

      .title {
        font-size: 18px;
        font-weight: 200;
        line-height: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $color-primary-dark;
      }

      &:last-child {
        .body,
        .right {
          border-bottom-color: transparent;
        }
      }

      &.user-deactivated {
        opacity: 0.35;
      }
    }

    &.user-deactivated {
      opacity: 0.35;
    }
  }

  .data-table {
    table {
      min-width: 100%;
      max-width: 100%;

      thead th,
      tbody td {
        border: none;
        border-bottom: solid 2px $border-color;
        margin: 0px;
        padding: 8px 0px;
        text-align: left;
        padding-right: 16px;
      }

      thead tr th:last-child,
      tbody tr td:last-child {
        padding-right: 0px;
      }

      thead th {
        padding-top: 16px;
        padding-bottom: 16px;
        line-height: 32px;
        font-weight: 800;
        color: $color-primary-dark;

        ._text_ {
          font-size: 16px;
        }

        ._prefix_ {
          font-size: 10px;
        }

        .filter {
          height: 26px;
          background-color: $color-light;
          border: 1px solid $border-color;
          border-radius: 4px;
          padding: 0px 4px;
          flex-direction: row;
          display: flex;
          margin-top: 4px;

          select,
          input {
            height: 24px;
            padding: 0px 8px;
            font-size: 12px;
            line-height: 22px;
            background-color: transparent;
            border: none;
            box-sizing: border-box;
            outline: none;
          }

          input {
            flex: 1;
          }
        }

        input.filter {
          padding: 0px 8px;
          font-size: 12px;
          line-height: 24px;
          outline: none;
          display: block;
          width: 100%;
        }
      }

      tbody td {
        border-bottom-width: 1px;
        white-space: nowrap;
        overflow: hidden;
        line-height: 32px;
        text-overflow: ellipsis;
        font-weight: 400;
        color: $color-primary-dark;

        a {
          color: $color-primary-dark;
        }

        .name {
          display: flex;
          flex-direction: row;

          .abrev {
            height: 32px;
            width: 32px;
            margin-right: 16px;
            line-height: 28px;
            font-weight: 400;
            font-size: 16px;
          }
        }
      }
    }

    .carets {
      padding-left: 8px;

      .sort {
        color: $border-color;
      }
    }
  }
}

.value-card-custom {
  margin: 0 30px 30px 15px !important;
  min-width: 350px !important;
  max-width: 350px !important;
  color: #393d58 !important;
}

.value-card {
  color: $color-primary-dark;
  display: flex;
  flex-direction: column;
  flex: 1;

  & > *:not(.card-inner) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .value-card {
    color: $color-primary-dark;
    display: flex;
    flex-direction: column;
    flex: 1;

    & > *:not(.card-inner) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .titleHolder {
      display: flex;
      flex-direction: row;
      align-items: center;
      max-width: 100%;

      .abrevContainer {
        display: block;
        margin-right: 16px;
      }

      .inner {
        display: flex;
        flex-direction: row;
        align-items: center;
        max-width: 100%;

        .abrevContainer {
          display: block;
          margin-right: 16px;
        }

        .inner {
          display: flex;
          flex-direction: column;
          flex: 1;
          height: 48px;

          &.hasAbrev {
            max-width: calc(100% - 64px);

            &.hasIcon {
              max-width: calc(100% - 104px);
            }
          }

          &.hasIcon {
            max-width: calc(100% - 40px);

            &.hasAbrev {
              max-width: calc(100% - 104px);
            }
          }
        }

        .iconContainer {
          margin-left: 16px;
        }
      }

      .title,
      .date {
        max-width: 100%;
      }

      .title span {
        font-size: 14px;
        font-weight: 600;
      }

      .date span {
        font-size: 12px;
        font-weight: 300;
      }

      .title span,
      .date span,
      .titleHolder h2 span {
        display: inline-block;
        width: auto;
        max-width: 100%;
        height: 16px;
        line-height: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .titleHolder h2 span {
        height: 48px;
        line-height: 48px;
      }

      &:not(.inverted) {
        .date {
          color: $border-color-dark;
        }
      }

      &.inverted {
        @each $key, $val in $i-color-list {
          &.#{$key} {
            background-color: #{$val} !important;
          }
        }
      }

      .value {
        font-size: calc(100vw * 0.25 * 0.085);
        font-weight: 800;
        padding: 24px 0px;

        .unit {
          font-size: 0.5em;
          font-weight: 600;
        }
      }

      .rows {
        display: flex;
        flex-direction: column;
        flex: 1;

        .row {
          display: flex;
          flex: 1;
          flex-direction: column;
          max-width: 100%;

          .col {
            display: flex;
            flex: 1;
            flex-direction: column;
            max-width: 100%;
            justify-content: center;
            color: $color-primary-dark;
            margin-top: 16px;

            &:nth-child(2) {
              padding-left: 8px;
            }

            &.noMargin {
              margin-top: 0px;
            }

            .label {
              font-size: 13px;
              font-weight: 600;
              padding: 0px 0px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              .badge {
                display: inline-block;
                padding: 0px 4px;
                height: 13px;
                line-height: 13px;
                font-size: 10px;
                font-weight: 600;
                font-style: normal;
                color: $color-light;
                border-radius: 7px;
                margin-left: 4px;
                background-color: map-get($i-color-list, red);
              }
            }

            &.labelBadge {
              .label span {
                display: inline-block;
                padding: 4px 16px;
                font-size: 12px;
                font-weight: 200;
                color: $color-light;
                border-radius: 4px;
                background-color: map-get($i-color-list, green);
              }

              @each $key, $val in $i-color-list {
                &.#{$key} {
                  .label span {
                    background-color: #{$val} !important;
                  }
                }
              }
            }
          }

          &.paddingBoxx {
            padding-top: 20px !important;
          }

          &labelNeigbour .label,
          .value {
            display: inline-block;
            font-size: 15px;
            font-weight: 200;
            padding: 0px 0px;

            &:nth-child(2) {
              padding-top: 2px;
            }

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &.labelFlat .label,
        .value {
          font-size: 15px;
          font-weight: 200;
          padding: 0px 0px;

          &:nth-child(2) {
            padding-top: 2px;
          }

          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &.footer {
          border-top: solid 1px $border-color;
          padding-top: 1em;
        }

        &.right * {
          text-align: right;
        }

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .col:first-child:not(:last-child),
      .col:last-child:not(:first-child) {
        max-width: 50%;
      }
    }
  }

  .chart {
    flex: 1;
    background-color: rgba($border-color, 0.05);
    display: flex;
    flex-direction: column;
    min-height: 80px;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border-radius: 6px;

    .recharts-wrapper {
      &,
      .recharts-surface {
        max-width: 100% !important;
      }
    }

    .col:first-child:not(:last-child),
    .col:last-child:not(:first-child) {
      max-width: 50%;
    }
  }
}

.my-filter-panel {
  &,
  & .pusher {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  & > .ui.segment:first-child,
  > .credit-topup {
    border-top: none;
    border-bottom: none;
    width: 320px;
    padding: 0px 0px;

    .header:not(.ui) {
      .title {
        font-size: 24px;
        line-height: 26px;
        text-transform: none;
      }
    }
  }
}

#top-item-selector {
  display: block;
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 99;

  .backdrop {
    display: absolute;
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0;
    font-size: 1px;
  }

  .inner {
    display: absolute;
    position: fixed;
    top: $sidebar-width;
    left: $sidebar-width + ($sidebar-menu-margin * 2) +
      ($sidebar-menu-size * 0.65);

    .icon {
      &:before,
      &:after {
        line-height: 10px !important;
        height: 10px !important;
      }

      display: block;
      overflow: hidden;
      font-size: 32px;
      width: 20px;
      color: $color-primary;
      line-height: 10px !important;
      height: 10px !important;
    }

    .scroll {
      display: flex;
      flex: 1;
      flex-direction: column;
      overflow: hidden;
      border-radius: 6px;
      -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
        0 2px 6px 2px rgba(60, 64, 67, 0.15);
      box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
        0 2px 6px 2px rgba(60, 64, 67, 0.15);

      .search {
        padding: 16px;
        border-bottom: solid 1px rgba($color-light, 0.25);
      }

      .items {
        display: flex;
        flex: 1;
        flex-direction: column;
        overflow: hidden;
        overflow-y: auto;
        max-height: 50vh;

        a {
          display: block;
          padding-right: 16px;
          line-height: 56px;
          margin-left: 16px;
          border-bottom: solid 1px rgba($color-light, 0.05);
          color: rgba($color-light, 0.5);
          font-size: 16px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 200;

          &:hover,
          &.active {
            color: $color-light;
          }

          &.active {
            font-weight: 600;
          }
        }
      }
    }
  }
}

.wallet {
  .pannel-scroll {
    padding-top: 32px;
  }

  .wallet-balance {
    width: 320px;
  }
}

.content-loader {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex: 1;
  background-color: $color-light;
  text-align: center;

  .button {
    margin: 16px 0px;
  }

  &.inverted {
    background-color: $color-primary;
  }

  .ui.header {
    justify-content: center;
  }
}

.the-wallet {
  & > .padding:first-child {
    padding-bottom: 0px;

    & > .some-sticky-stuff {
      position: -webkit-sticky;
      position: -moz-sticky;
      position: -ms-sticky;
      position: -o-sticky;
      position: sticky;
      top: 0;
      background-color: $color-light;
      z-index: 4;
    }

    .header:not(.ui) {
      margin-bottom: 0px !important;
      border-bottom: none !important;
    }

    & > .ui.grid:nth-child(2) {
      .row {
        .column {
          .value-card {
            .value {
              padding: 0px 0px 0px 0px !important;
            }
          }
        }
      }
    }
  }
}

.my-data-table {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-bottom: 0px !important;

  & > .header:not(.ui) {
    // margin-bottom: 0px !important;

    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
    position: sticky;
    top: 0;
    background-color: $color-light;
    z-index: 3;
  }

  .data-table {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-bottom: 0px !important;

    & > .header:not(.ui) {
      // margin-bottom: 0px !important;

      position: -webkit-sticky;
      position: -moz-sticky;
      position: -ms-sticky;
      position: -o-sticky;
      position: sticky;
      top: 0;
      background-color: $color-light;
      z-index: 3;
    }

    .data-table {
      display: flex;
      flex: 1;
      flex-direction: column;
      // overflow: hidden;
      // overflow-y: auto;
      overflow: unset !important;
      margin-left: -16px;
      margin-right: -16px;
      padding-left: 16px;
      padding-right: 16px;

      table {
        tbody {
          min-width: 100%;

          tr {
            min-width: 100%;
          }
        }

        thead {
          min-width: 100%;

          // position: -webkit-sticky;
          // position: -moz-sticky;
          // position: -ms-sticky;
          // position: -o-sticky;
          // position: sticky;
          // top: 0;
          // background-color: rgba($color-light, 0.95);

          tr {
            min-width: 100%;

            th {
              // padding-top: 0px !important;
              // padding-bottom: 0px !important;
              position: -webkit-sticky;
              position: -moz-sticky;
              position: -ms-sticky;
              position: -o-sticky;
              position: sticky;
              // top: 0;
              top: 50px;
              background-color: rgba($color-light, 0.95);
            }
          }
        }
      }
    }
  }
}

.my-select {
  & > .my-select-text {
    display: block;
    cursor: pointer;
    position: relative;

    &.placeholder {
      opacity: 0.65;
    }

    span:first-child {
      color: rgba(0, 0, 0, 0);
    }

    span:last-child {
      display: inline-block;
      height: 1em;
      line-height: 1em;
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      position: absolute;
      left: 0px;
      top: 0px;
    }
  }

  & > .loader {
    display: block;
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    z-index: 3;
    width: 36px;

    .loader {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      position: absolute;
      display: block;
    }
  }
}

.equal.width.fields {
  .field {
    max-width: 50% !important;
  }

  .ui.button {
    height: 38px !important;
    align-self: flex-end !important;
    margin-left: 0.5em;
    margin-right: 0.5em;
  }
}

.my-select-modal {
  .ui.checkbox label {
    padding-left: 32px !important;
  }

  .my-select-header {
    align-items: center;
    justify-content: center;

    label {
      font-size: 18px;
    }
  }

  .content {
    max-height: calc(100vh - 240px) !important;
    overflow: hidden;
    overflow-y: auto;

    .items {
      .item {
        &:not(:last-child) {
          padding-bottom: 16px;
        }
      }
    }
  }
}

.text-center {
  text-align: center;
}

.block-center {
  text-align: center;
}

.block {
  display: block;
}

.hide {
  display: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.ui.form .field > .selection.dropdown {
  text-transform: capitalize;
}

.overlap {
  z-index: 99999 !important;
}

.ui.secondary.pointing.menu {
  margin: 0;
}

.ui.pointing.secondary.menu {
  font-size: 1.2em !important;
  font-weight: 300 !important;
}

.sidebar {
  display: block;
  padding: 16px;

  .header:not(.ui) {
    display: flex;
    height: calc(#{$sidebar-width} * 0.75);
    flex-direction: row;
    font-size: 14px;
    font-weight: 600;
    color: $color-primary-dark;
    align-items: flex-end;
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: solid 1px $border-color;

    .title:not(.titleText) {
      font-size: 14px;
      line-height: 16px;
      text-transform: uppercase;
    }

    .back {
      line-height: 26px;
      height: 26px;
      padding-right: 32px;
      cursor: pointer;
    }

    &.active {
      color: $color-primary-dark;
      font-weight: 600;
    }
  }
}

.ui.sidebar {
  position: fixed;
  top: 125px;
  width: 200px;
}

// Chart legends
.bar-chart-container {
  .recharts-legend-wrapper {
    width: auto !important;
    left: 0px !important;
    right: 0px !important;

    .recharts-default-legend {
      display: flex !important;
      flex-direction: row !important;
      width: 100% !important;
      align-items: center !important;
      justify-content: center !important;

      li {
        width: 80px;
      }

      .recharts-legend-item {
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
        justify-content: center !important;
      }
    }
  }
}

.organizations-overview {
  .sixteen {
    .grid {
      .two {
        .eight.wide {
          .card {
            margin-left: 1px !important;
          }
        }
      }
    }
  }
}

.action-prevented {
  cursor: not-allowed !important;
  pointer-events: auto;
  opacity: 0.4;
}

.status-icon {
  margin-right: 10px;
}

.pie-chart-wrapper {
  .recharts-default-legend {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.dropdown-withID {
  .ui.dropdown .menu > .item > .description,
  .ui.dropdown > .text > .description {
    margin: 0 5px 0 0em;
  }
}

.disabled-label {
  opacity: 0.45;
}
.disabled {
  opacity: 0.45;
}

.deactivated-user {
  opacity: 0.2;
}
