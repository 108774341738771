.setting {
  &__org {
    padding-top: 30px;
    form {
      hr {
        border: solid 0.5px #eee;
      }

      button[name='submit-btn'] {
        color: #fff;
        background-color: #2185d0;
      }
    }
  }
  &__add-feature {
    padding-top: 0px !important;
    form {
      padding-top: 16px;
    }

    .payload-label {
      margin-right: 15px !important;
    }
  }
  &__features {
    padding-top: 16px;

    &-info {
      width: 451px;
      height: 300px;
      display: flex;
      flex-direction: column;

      h5 {
        margin-bottom: 0;
      }

      &__title {
        font-size: 20px;
        font-weight: 700;
        margin-top: 4px;
      }
      &__details {
        display: flex;
        &--left,
        &--right {
          width: 220px;
        }
      }
      &__description {
        margin-top: 16px;
      }
      .divider {
        width: 450px;
      }
    }

    table {
      tbody {
        display: block;
        height: 75vh;
        overflow: auto;
      }
      thead,
      tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;

        .ellipsis-dropdown-options {
          margin-left: 15px;
        }
      }
    }
  }
  &__add-feature,
  &__commission {
    padding-top: 30px;
    padding-bottom: 50px;
  }
}

.org-setting {
  padding-top: 0px;
  form {
    hr {
      border: solid 0.5px #eee;
    }

    button[name='submit-btn'] {
      color: #fff;
      background-color: #2185d0;
    }
  }
}

.ui.form .disabled.field {
  opacity: 0.9;
  cursor: not-allowed;
  pointer-events: auto;
}

.ui.table tr td {
  border-top: 0px !important;
}
