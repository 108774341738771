@import '../../../assets/styles/colors.scss';

.list-projects {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  &.not-all {
    flex-grow: 0.6;
    margin-left: 16px;
  }
  &:not(.not-all) {
    .save-dash-toolbar {
      margin-left: 16px;
    }
  }
  .list-projects-items {
    padding-left: 16px;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
    overflow-y: auto;
    .list-projects-item {
      display: flex;
      align-items: flex-start;
      padding: 10px 0 0;
      margin-bottom: 10px;

      &:hover {
        cursor: pointer;
        background-color: #f8f8f8;
      }

      .thumbnail {
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 20px;
        object-fit: cover;
      }

      .details {
        flex-grow: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 10px;
        border-bottom: solid 2px rgba(72, 78, 115, 0.1);

        .info {
          .name {
            font-size: 1.2em;
            padding-bottom: 5px;
          }

          .contact {
            color: #888;
            font-size: 0.9em;
            font-weight: 400;

            span {
              margin-right: 50px;
            }
          }
        }

        .roles {
          .role {
            font-size: 0.9em;
            color: #fff;
            padding: 2px 12px;
            border-radius: 5px;
            background-color: $color-label-green;
          }
        }
      }
    }
  }
}
