.group-activities {
  padding: 16px;
  .scroll-wrapper {
    display: block;
  }
}
.my-filter-panel {
  &,
  & .pusher {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
  & > .ui.segment:first-child,
  > .credit-topup {
    border-top: none;
    border-bottom: none;
    width: 320px;
    padding: 0px 0px;
    .header:not(.ui) {
      .title {
        font-size: 24px;
        line-height: 26px;
        text-transform: none;
      }
    }
  }
}

.not-link {
  cursor: text;
  color: rgba(0, 0, 0, 0.87);
  &:hover {
    color: rgba(0, 0, 0, 0.87);
  }
}

.ui.wide.right.sidebar {
  width: 305px;
}
