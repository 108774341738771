.wallet {
  overflow: hidden !important;

  .dash-toolbar {
    width: 84%;
  }

  &__balance {
    display: flex;
    overflow: auto;
    padding: 0.4rem 2rem 0 0.2rem;
    margin-top: 1rem;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .loader {
    margin-top: 20vh !important;
  }

  .sidebar {
    position: fixed;
    top: 80px;
  }

  &__footer-wrapper {
    display: flex;
    justify-content: flex-end;

    .save-dash-toolbar {
      width: 100%;
    }
  }

  &__top-up {
    margin-right: 20px;
  }

  .balances {
    display: flex;
    overflow: auto;
  }

  .save-dash-toolbar-footer {
    margin-right: 10px;
  }
}
