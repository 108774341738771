#new-user {
  margin-left: 8px !important;
}

.organization-users {
  .details {
    .name {
      color: #000;
    }
  }
  .right-info {
    display: flex;

    .inactive {
      text-transform: capitalize;
      font-size: 0.9em;
      display: inline-block;
      padding: 5px 10px;
      background-color: #57d9a3;
      color: #fff;
      border-radius: 3px;
      margin-right: 20px;
      background-color: #fc5739 !important;
    }
    .badge {
      &__blue {
        background-color: #4d99ff !important;
      }

      &__inactive,
      &__blocked {
        background-color: #fc5739 !important;
      }

      &__invited {
        background-color: #21ba45 !important;
      }
    }
  }

  .status-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 23px;
    height: 23px;
    border-radius: 50%;
    color: #fff;
    font-size: 1.1em;
    margin-left: 20px;
    background-color: #4d99ff;
    margin-right: 20px !important;
    outline: none !important;

    span {
      margin-top: 2px;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
