@import '../../../../../assets/styles/colors.scss';

section.bulk-create {
  width: 100vw;
  // height: 100vh;
  overflow-y: scroll;
  max-height: 80vh;
  padding-bottom: 21vh;
  overflow: auto;
  position: absolute;
  margin-top: -10px;
  margin-left: -10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 3;
  font-size: 1.3em;
  font-weight: 300;
  padding-top: 20px;
  padding-right: 100px;

  input#file {
    display: none;
  }

  .link {
    color: #2185d1;

    &:hover {
      cursor: pointer;
    }
  }

  p {
    color: #111;
    margin-bottom: 5px;
  }

  .action {
    display: block;
    padding-bottom: 5px;
  }

  .heading{
    margin-top: 2rem;
  }
  .drag-box {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 20px 20px 10px;
    width: 55%;
    height: 40%;
    min-height: 220px;
    margin: 20px 0 10px;
    border-radius: 8px;
    background: linear-gradient(
        to right,
        #999 50%,
        rgba(255, 255, 255, 0) 0%
      ),
      linear-gradient(#999 50%, rgba(255, 255, 255, 0) 0%),
      linear-gradient(to right, #999 50%, rgba(255, 255, 255, 0) 0%),
      linear-gradient(#999 50%, rgba(255, 255, 255, 0) 0%);
    background-position: top, right, bottom, left;
    background-repeat: repeat-x, repeat-y;
    background-size: 20px 3px, 3px 20px;

    &:hover {
      cursor: pointer;
    }

    .format {
      margin-top: 10px;

      span {
        color: #999;
      }

      div {
        margin-top: 20px;
        display: flex;
        justify-content: center;

        img {
          width: 30px;
          height: 30px;
          object-fit: contain;
          margin: 0 5px;
          margin-bottom: 10px;
        }
      }
    }

    img.file {
      width: 60px;
      height: 60px;
      object-fit: contain;
      margin-bottom: 10px;
    }
  }

  .loading-box {
    width: 55%;
    margin-top: 10px;
    border-radius: 8px;
  }

  .upload-box {
    width: 55%;
    margin-top: 10px;
    padding: 20px 20px 0;
    border: 1px solid #ccc;
    border-radius: 8px;

    .top > div {
      display: flex;
      width: 100%;
      flex-direction: row;
    }

    .top.error {
      flex-direction: row;

      button {
        border: 0px solid #fff;
      }
    }

    .top {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      font-size: 0.9em;
      font-weight: 500;
      margin-bottom: 10px;

      img {
        margin-left: 10px;
        width: 20px;
        height: 20px;
      }

      .cancel-image {
        &:hover {
          cursor: pointer;
        }
      }

      .top-error {
        display: flex;
        align-items: center;

        span {
          font-size: 1.2em;
          color: #ff5361;
          padding-left: 10px;
        }
      }
    }

    .error-message {
      font-size: 0.9em;
      font-weight: 300;
      line-height: 1.2;
      padding-bottom: 10px;

      &__summary{
        display: block;
        margin-bottom: 0.5rem;
      }
    }
  }
  .ui.message {
    min-height: auto;
  }
}
