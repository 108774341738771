@import '../../assets/styles/colors.scss';

.confirm-loan-approval {
  &__pin-input {
    margin: 5px 0 15px 0 !important;
  }

  &__select-input {
    margin: 5px 0 8px 0;

    label {
      font-size: 1.2em !important;
      font-weight: 400 !important;
      margin-bottom: 5px !important;
    }
  }
}

.confirm-loan-decline {
  &__pin-input {
    margin: 5px 0 15px 0 !important;
  }
  &__reason-input {
    margin: 0 0 15px 0 !important;
  }

  &__select-input {
    margin: 5px 0 8px 0;

    label {
      font-size: 1.2em !important;
      font-weight: 400 !important;
      margin-bottom: 5px !important;
    }
  }
}

.confirm-loan-amend {
  &__pin-input {
    margin: 5px 0 15px 0 !important;
  }
  &__reason-input {
    margin: 5px 0 15px 0 !important;
  }

  &__select-input {
    margin: 5px 0 8px 0;

    label {
      font-size: 1em !important;
      font-weight: 400 !important;
      margin-bottom: 5px !important;
    }
  }

  &__interests {
    padding: 15px 0 15px 0;
    font-size: 1.1em;
    line-height: 2rem;
    color: $color-dark;

    &__value {
      font-weight: 600;
    }
  }
}

.create-profile-content {
  margin-bottom: 22px;
  font-size: 1.1rem;
  font-weight: 400;
}

.list-loan-requests {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: calc(100vh - 290px);

  &.not-all {
    flex-grow: 0.6;
    margin-left: 16px;
  }
  &:not(.not-all) {
    .save-dash-toolbar {
      margin-left: 16px;
    }
  }

  &__items {
    padding-right: 16px;
    overflow: hidden;

    &__table {
      padding: 16px 0 0;

      &__tbody {
        overflow-y: auto;
        height: calc(100vh - 290px);
      }

      table {
        thead {
          th:first-child {
            width: 15%;
          }
          th {
            word-break: normal !important;
          }
        }

        tr {
          display: flex;
          td:first-child {
            width: 15%;
          }
        }

        .table-data {
          display: flex;
          align-items: center;
          width: 8.5%;
          word-break: break-word;
        }
      }
    }

    &__item {
      padding: 10px 0 0;
      margin-bottom: 10px;

      display: grid;
      grid-template-columns: 60px 1fr;

      &:hover {
        cursor: pointer;
        background-color: #f8f8f8;
      }

      .thumbnail {
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 20px;
        object-fit: cover;
      }

      &__details {
        flex-grow: 1;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 10px;
        border-bottom: solid 2px rgba(72, 78, 115, 0.1);

        &__info {
          &__name {
            font-size: 1.2em;
            padding-bottom: 5px;
            font-weight: 400;
          }

          &__numbers {
            color: #888;
            font-size: 0.9em;
            font-weight: 400;
            width: 100%;
            line-height: 1.5;
            display: grid;
            grid-template-columns: 34% 34% 16% 16%;

            .number-group {
              margin-right: 50px;
              display: flex;
              align-items: flex-start;

              &__icon {
                margin: 0 5px !important;
              }
            }
          }
        }
      }
    }
  }
}

.loan-request-info-popup {
  width: 356px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;

  &__header {
    display: flex;
    justify-content: space-between;
    font-size: 1.2rem;
    font-weight: 700;
    padding: 15px;
    border-bottom: #393d584d 1px solid;
  }

  &__body {
    overflow: hidden;
    overflow-y: auto;
    height: 600px;

    &__loan-info {
      padding: 20px;
      border-bottom: #393d584d 1px solid;

      &__item {
        font-size: 1em;
        padding-bottom: 4px;
        color: #000000de;
        display: flex;
        justify-content: space-between;
      }
    }
    &__committe-info {
      padding: 20px;
      border-bottom: #393d584d 1px solid;

      &__title {
        font-size: 1em;
        font-weight: 700;
        margin-bottom: 10px;
      }

      &__item {
        font-size: 1em;
        padding-bottom: 4px;
        color: #000000de;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__member {
          display: flex;
          flex-direction: column;
        }
        &__phone {
          font-size: 0.8rem;
          font-weight: 400;
          color: #989aa8;
          line-height: 12px;
        }
      }
    }
    &__orgs-info {
      padding: 20px;

      &__title {
        font-size: 1em;
        font-weight: 700;
        margin-bottom: 10px;
      }

      &__item {
        font-size: 1em;
        padding-bottom: 4px;
        color: #000000de;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__member {
          display: flex;
          flex-direction: column;
          color: map-get($i-color-list, blue);
        }
        &__comment {
          display: flex;
          flex-direction: column;
        }
        &__comment {
          font-size: 1rem;
          font-weight: 400;
          color: #989aa8;
        }
      }
    }
  }

  &__approved {
    color: $color-new-green !important;
  }
  &__pending {
    color: $color-new-yellow !important;
  }
  &__rejected {
    color: $color-new-red !important;
  }
  &__no-comment {
    color: #989aa8 !important;
  }
}
