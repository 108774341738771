.areas-of-intervention {
  margin-bottom: 20px;
  &__title {
    display: flex;
    align-items: center;
    font-size: 1.4em;
    font-weight: 400;
    padding-bottom: 30px;
    margin: 30px 0 5px !important;
  }
  &__button {
    width: 60px;
    button {
      background-color: transparent;
      border: none;
    }
  }
  .disabled.field {
    opacity: 0.7 !important;
    .ui.disabled.selection.dropdown {
      opacity: 1 !important;
    }
  }
}
