.member-modal {
  .content {
    table {
      th {
        font-weight: normal;
      }
      td {
        border: none !important;
        padding: 0;
        text-align: right;
        color: rgba(0, 0, 0, 0.582);
      }
    }
  }
}
