.member__loans {
  margin-left: 10px;
}

.loan-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 5px;
  padding: 5px 0;
  min-height: 60px;
  outline: none;

  &:hover {
    cursor: pointer;
    background-color: #f8f8f8;
  }

  .thumbnail {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 20px;
    object-fit: cover;
  }

  .details {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 5px;
    border-bottom: solid 2px rgba(72, 78, 115, 0.1);

    &:hover {
      cursor: pointer;
      background-color: #f8f8f8;
    }

    .info {
      .name {
        font-size: 1.2em;
        padding-bottom: 5px;
      }

      .member {
        display: flex;
        align-items: center;
        color: #888;
        font-size: 0.9em;
        font-weight: 400;
        margin-top: 2px;

        .member-item {
          display: flex;
          min-width: 200px;
          max-width: 200px;
          overflow: hidden;
          justify-content: flex-start;
          align-items: center;
          text-overflow: ellipsis;
          margin-right: 20px;
          white-space: nowrap;

          b {
            margin-right: 5px;
          }

          .loan-item-icon {
            margin-right: 10px;
          }
        }
        .time {
          max-width: 250px !important;
          min-width: 200px !important;
        }
      }
    }
  }
}
