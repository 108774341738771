.request {
  display: flex;
  padding: 20px;

  &__left {
    width: 55%;

    &__item {
      margin: 10px 0 10px 0;
      font-weight: bold;
    }
    .request__left--btns {
      display: flex;
      justify-content: space-between;
      margin: 50px 10px 20px 10px;
    }
  }

  &__right {
    width: 40%;

    &__title {
      margin-top: 10px;
      font-weight: 800;
      font-size: 12px;
    }

    &__image {
      height: 120px;
    }

    &--items {
      font-size: 10px;
      color: #1b8bd1;
      display: flex;
      justify-content: space-between;

      .request__right--items:hover {
        cursor: pointer;
      }
    }
  }

  &__declined {
    padding: 20px;
    font-size: 20px;
  }
}
