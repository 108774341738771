.activity {
  &__item {
    &--details {
      display: flex;
      width: 100%;
    }

    &--title {
      display: none;
      margin-bottom: 5px;
      width: 100%;
      position: relative;
      font-size: 18px;
    }
  }

  &__thumbnail {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    border-radius: 50%;
    margin-right: 20px;
    object-fit: cover;
  }

  &__info {
    width: 100%;
  }
}
