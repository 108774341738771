@import '../../../assets/styles/colors.scss';

.save-dash-toolbar {
  display: flex;
  flex-direction: column;
  .save-dash-toolbar-header {
    display: flex;
    flex-direction: row;
    height: 64px;
    align-items: center;
    border-bottom: 1px solid $border-color;
    padding-right: 16px;
    .back-button {
      background-color: transparent;
      cursor: pointer;
      border: none;
      text-align: left;
      padding: 4px 0px 4px 0px;
      width: 48px;
      margin-right: 16px;
      margin-top: -10px;
      outline: none;
      color: $color-primary-dark;
      &:hover {
        opacity: 0.65;
      }
    }
    .title {
      padding-right: 16px;
      min-width: 0;

      h2 {
        color: $color-primary-dark;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .breadcrumb {
        font-size: 1.71428571rem;
        margin-top: -10px;
        display: flex;
        &.tag {
          padding-top: 16px !important;
        }
        a {
          color: $color-primary-dark;
          font-weight: 600;
          &:hover.breadcrumb-title {
            opacity: 0.9;
            text-decoration: underline;
          }
          &.breadcrumb-title {
            max-width: calc(100vw - 847.5px);
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .icon {
          margin-top: 0.09em;
          color: rgba(50, 54, 82, 0.891);
        }
        .active {
          a {
            color: $color-dark;
            font-weight: 700;
            cursor: unset;
          }
        }
        .chevron {
          margin-top: 3px;
        }
      }
      .dropdown {
        &__title {
          display: inline;
        }
        &__menu {
          color: $color-primary-dark;
          margin-left: 1rem;
          font-size: 1.8rem;
          .menu > .item {
            font-size: 1.2rem;
            font-weight: bold;
            color: inherit;
          }
        }
      }
    }
    .right-component {
      display: flex;
      flex-direction: row;
      margin-left: auto;
    }
    .left-component {
      display: flex;
      flex-direction: row;
      & > * {
        margin-right: 5px !important;
      }
    }
  }
}
