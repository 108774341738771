.withdrawal {
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
  &__item {
    &--title {
      font-weight: bold;
      font-size: 16px;
      padding-right: 5px;
    }
    &--drop {
      margin-top: 20px;
    }
    &--button {
      margin-bottom: 20px !important;
    }
    margin: 10px 0 10px 0;
  }
}
