.org-request-approval-form {
  .confirm-transaction-input {
    margin: 5px 0 15px 0 !important;
  }

  .org-requests-amend-inputs {
    display: flex;
    justify-content: space-between;
    margin: 5px 0 8px 0;
  }
}
