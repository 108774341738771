@import '../../../../../assets/styles/colors.scss';
.activity {
  &__item {
    padding: 10px 0 0;
    margin-bottom: 10px;

    &--details {
      width: 100%;
    }

    &--title {
      display: inline-block;
      margin-bottom: 5px;
      width: 100%;
      position: relative;
      font-size: 18px;
      text-transform: capitalize;
    }
  }

  &__thumbnail {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    border-radius: 50%;
    margin-right: 20px;
    object-fit: cover;
  }
}

.activity {
  .activities .activity__item--details {
    flex-direction: row;
  }
  &__item {
    display: flex;
    flex-direction: row;
    margin-bottom: 0;
    .divider {
      margin: 5px;
    }

    &--details {
      display: flex;
      flex-direction: column;
      &__body {
        display: flex;
        justify-content: space-between;
        &__approvalStatus {
          display: flex;
        }
        &__statusDot {
          height: 10px;
          width: 10px;
          border-radius: 5px;
          margin: 6px;
          background: #db2829;
          &.approved,
          &.accepted,
          &.success {
            background: #21ba45;
          }

          &.pending,
          &.submitted {
            background: #e9d631;
          }
          &.rejected,
          &.declined {
            background: #db2829;
          }
        }
      }
    }
  }

  &__info-card {
    display: flex;
    align-items: center;
    width: 80px;
    margin-right: 20px;
    cursor: pointer;
    &__label {
      color: $color-incon-blue;
      font-size: 18px;
      text-decoration: underline;
      cursor: pointer;
      img {
        display: inline !important;
        margin-right: 4px;
      }
    }
    &__description {
      height: 328px;
      width: 363px;
      &__header {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        &__title {
          font-weight: 700;
          font-size: 20px;
          color: $color-dark;
        }
        &__status {
          font-size: 18px;
          &--success,
          &--approved {
            color: map-get($i-color-list, green);
          }
        }
      }
      &__content {
        display: flex;
        justify-content: space-between;
        &--left,
        &--right {
          display: flex;
          flex-direction: column;
        }
        &--right {
          align-items: flex-end;
        }
      }
    }
  }
}
