@import '../../../../../assets/styles/colors.scss';

.single-loan-request {
  padding-left: 16px;

  &__content {
    &__top {
      display: flex;
      flex-wrap: wrap;
      gap: 27px 30px;
      margin-bottom: 60px;

      &__item {
        box-shadow: 0px 0px 15.7862px rgba(0, 0, 0, 0.1);
        border-radius: 3.15724px;
        padding: 15px 20px;
      }
    }
  }

  .green-dot {
    width: 8px;
    height: 8px;
    background: $color-label-green;
    border-radius: 50%;
    margin-right: 5px;
  }
  .red-dot {
    width: 8px;
    height: 8px;
    background: $color-new-red;
    border-radius: 50%;
    margin-right: 5px;
  }

  .groups-table {
    padding: 16px 0 0;
    table {
      thead {
        th:first-child {
          width: unset;
        }
      }

      tbody {
        display: block;
        height: calc(100vh - 290px);
        overflow-y: auto;
        overflow-x: hidden;

        td {
          padding: 0.7rem 0 !important;
        }
      }
    }
  }
}

.internal-loan-overview-modal {
  width: 645px !important;
  max-width: 700px;

  &__container {
    display: flex;
    flex-wrap: wrap;
  }
}
