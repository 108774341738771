.transfer-groups,
.remove-agent {
  .groups-field {
    display: flex !important;
    .group-select {
      margin-right: 0.5rem;
      &.disabled label {
        cursor: not-allowed !important;
      }
    }
    label.disabled {
      color: rgba(0, 0, 0, 0.4);
    }
  }
  .transfer__button,
  .remove__button {
    margin: 20px 0;
    float: right;
  }
}
