@import '../../../../assets/styles/colors.scss';

.checkbox {
  display: flex;
  margin-bottom: 10px;
  .checkbox-content {
    padding-left: 10px;
    color: $color-dark-grey;
  }
}
