.SingleOrganization {
  width: auto !important;
}

.organization-settings-wrapper {
  display: flex;
  flex: 1;
  overflow: hidden;
  .scroll-wrapper {
    padding-top: 0px;
  }
  .SingleOrganization {
    width: auto !important;

    .block {
      margin-top: 100px !important;
      width: 100% !important;
      border-right: 0px solid #fff !important;
    }
  }
}
