.eye-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  outline: none;

  i {
    color: #9a9b9a;
  }

  &:hover {
    cursor: pointer;
  }
}
