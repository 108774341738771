.simple-modal {
  .header {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
  }

  &__content {
    font-size: 1.1em;
  }
}
