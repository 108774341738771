.invite-agent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__container {
    width: 40%;
    margin-top: 20px;

    &--title {
      font-size: 1.5em;
      padding-bottom: 40px;
    }
  }
}
