.list-organizations {
  section.dash-toolbar {
    width: 100% !important;
  }

  section.dash-toolbar + div {
    margin-top: 0;
  }

  .list-organizations-items {
    padding-left: 16px;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    overflow-y: auto;
  }
}
