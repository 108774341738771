.cycle_filter_options {
  border: none;

  &__title {
    margin: 0px 10px;
    font-size: 13px;
    font-weight: bold;
  }
}

.cycle_filter_options__no-margin {
  margin: 0 0 10px 0;
}
.cycle_filter_options__margin {
  margin: 0 10px 0 0;
}
