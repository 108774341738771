.organization-settings-wrapper {
  display: flex;
  flex: 1;
  overflow: hidden;

  .SingleOrganization {
    .block {
      margin-top: 100px !important;
      width: 100% !important;
      border-right: 0px solid #fff !important;
    }
  }

  .organization-users {
    width: 100% !important;
    .userlist {
      max-height: 84%;
      overflow-y: auto;
    }
    &.no-vertical-padding {
      .scroll-wrapper {
        margin-top: 0px;
        margin-bottom: -16px;
      }
    }
  }
}
