.receipt {
  width: 100%;
  background-color: #fff;
  border-radius: 5px;

  &__header {
    margin-top: 1.2rem;
    &--save {
      padding: 12px 20px;

      img {
        width: 100px;
        object-fit: contain;
      }
    }

    &--title {
      height: 30px;
      background-color: #1799af;

      div {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        right: 10%;
        font-size: 22px;
        padding: 0px 20px;
        background-color: #fff;
      }
    }

    &--logo {
      width: 5.5rem;
    }
  }

  &__details {
    padding: 20px 30px;

    .row {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    &--item {
      flex: 1;
      max-width: 50%;
      padding-bottom: 20px;

      &--title {
        font-size: 1.2em;
        font-weight: 600;
      }

      &--value {
        font-size: 1.2em;
        font-weight: 400;

        &--desc {
          font-size: 1.1rem;
        }
      }
    }

    &--description {
      margin-bottom: 40px;

      &--title {
        width: 100%;
        color: #fff;
        font-weight: 600;
        padding: 5px 20px;
        background-color: #333;
      }

      &--content {
        padding: 10px 20px;
        border: 1px solid #666;
        height: 150px;
      }
    }
  }

  &__channel {
    .row {
      position: relative;
    }

    &--paid {
      width: 50%;

      &--amount {
        position: absolute;
        width: 250px;
        color: #fff;
        font-size: 1.2em;
        font-weight: 600;
        padding: 3px 15px;
        background-color: #1799af;
        margin-bottom: 10px;
      }

      img {
        margin-top: 40px;
        width: 180px;
        object-fit: contain;
      }
    }
  }

  &__note {
    width: 100%;
    display: flex;
    font-size: 0.9em;
    justify-content: center;
    align-items: center;
    margin: 15px 0;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #1799af;
    padding-top: 3px;
    margin-top: 30px;

    &--item {
      font-size: 0.8em;
    }
  }
}

.simple-modal {
  position: fixed !important;
  width: 500px !important;
  max-width: 500px;
  right: calc(50vw - 250px) !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
