section.group-list {
  .group-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 5px;
    padding: 5px 0;
    min-height: 60px;

    &:hover {
      cursor: pointer;
      background-color: #f8f8f8;
    }

    .thumbnail {
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 20px;
      object-fit: cover;
    }

    .details {
      flex-grow: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 5px;
      border-bottom: solid 2px rgba(72, 78, 115, 0.1);

      &:hover {
        cursor: pointer;
        background-color: #f8f8f8;
      }

      .info {
        .name {
          font-size: 1.2em;
          padding-bottom: 5px;
        }

        .contact {
          display: flex;
          align-items: center;
          color: #888;
          font-size: 0.9em;
          font-weight: 400;
          margin-top: 2px;

          span {
            display: flex;
            min-width: 200px;
            max-width: 200px;
            justify-content: flex-start;
            align-items: center;
            margin-right: 40px;
            text-overflow: ellipsis;
            white-space: nowrap;

            b {
              margin-right: 5px;
            } 
            &.contact {
              &__type {
                max-width: 180px;
                min-width: 180px;
                margin-right: 20px;
              }
              &__time{
                margin-right: 30px;
              }
              &__amount {
                max-width: 230px;
                min-width: 230px;
                margin: 0 20px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              &__user {
                max-width: 210px;
                min-width: 210px;
                margin: 0;
                display: inline-block;
                overflow: hidden;
              }
            }

            .group-item-icon {
              margin-right: 10px;
            }
          }
        }
      }

      .status {
        width: 60px;
        text-align: center;
        text-transform: capitalize;
        font-size: 0.9em;
        display: inline-block;
        padding: 5px 10px;
        background-color: #57d9a3;
        color: #fff;
        border-radius: 3px;
        margin-right: 20px;
      }

      .inactive {
        background-color: #fc5739 !important;
      }
    }
  }
}
