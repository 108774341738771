
.internal-loan-overview {
  &__upper {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }

  &__lower {
    display: flex;
    justify-content: space-around;
  }
}
