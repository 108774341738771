@import '../../../assets/styles/colors.scss';
.profile-info,
.profile-interest {
  padding-left: 16px;

  .customized-title {
    a {
      color: map-get($i-color-list, royalBlue);

      &:hover {
        color: map-get($i-color-list, royalBlue);
      }
    }
  }

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 3rem;

    .group-items {
      align-items: center;
      .input {
        .label {
          border: 1px solid map-get($i-color-list, silverGray);
          background-color: map-get($i-color-list, silverGray);
          font-weight: 500;
        }
      }
      &__label {
        &--amount {
          text-align: center;
          text-transform: capitalize;
          font-size: 0.8em;
          display: inline-block;
          padding: 2px 8px;
          background-color: map-get($i-color-list, sapphireBlue);
          color: $color-light;
          border-radius: 3px;
          margin-right: 4px;
          margin-bottom: 4px;
        }
      }
    }

    .input-separator {
      width: 15px;
      height: 2px;
      background-color: map-get($i-color-list, silverGray);
    }

    .wrapper {
      width: 55%;
      min-width: 350px;

      .title {
        font-size: 1.4em;
        font-weight: 600;
        padding-bottom: 30px;
        display: flex;
        .back-button {
          background-color: transparent;
          cursor: pointer;
          border: none;
          text-align: left;
          padding: 4px 0px;
          width: 48px;
          margin-right: 16px;
          margin-top: -10px;
          outline: none;
          color: $color-primary-dark;
          &:hover {
            opacity: 0.65;
          }
        }
      }

      .cards {
        display: flex;
        justify-content: space-between;
        text-align: center;
        .card-primary,
        .card-secondary {
          border: 0.2rem solid;
          border-radius: 0.5rem;
          padding: 1rem 2.2rem;
          .card-title,
          .card-text {
            margin-bottom: 1rem;
          }
        }
        .card-primary {
          background-color: #2185d0;
          color: white;
        }
      }

      .error {
        input {
          color: #db2828;
          border-color: #db2828;
        }
      }
    }
  }

  .areas-of-intervention {
    &__title {
      font-weight: 600;
    }
  }
}
