.cycle {
  margin: 0 0 8px !important;

  &__header {
    padding-bottom: 20px;
    border-bottom: 1px solid #e0e0e0;

    &--name {
      font-size: 1.2em;
      font-weight: 400;
      padding-bottom: 8px;
    }

    &--date {
      font-weight: 300;
      color: #989ba8;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    padding: 15px 0 25px;

    &--count {
      font-size: 2.3em;
      font-weight: 400;
      margin-right: 8px;
    }

    &--count_label {
      font-size: 1em;
    }
  }
}

.cycle:last-child {
  margin: 0 !important;
}
