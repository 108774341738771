.textarea {
  textarea {
    resize: none !important;
    min-height: 150px !important;
  }
}
.sidebar {
  top: 142px;
  padding-bottom: 150px;
  &.scrollable {
    overflow-y: scroll;
    padding-bottom: 150px;
  }
  &__submit-btn.button {
    margin-top: 1rem;
  }
}
