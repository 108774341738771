.group__lists {
  .save-dash-toolbar-header {
    margin: 16px;
  }

  &__status__action {
    margin-right: 1rem;
  }

  &__confirmation__modal {
    .content {
      display: none;

      .dropdown {
        margin: 11px 0;

        .label {
          color: #868585;
        }
      }
    }
  }
}

.cursor {
  cursor: pointer;
}

.groups-table {
  padding: 16px 0px 5px;

  table {
    thead {
      th:first-child {
        width: 28%;

        span {
          display: block;
          margin-left: 2rem;
        }
      }

      .action-check {
        top: 3px;
      }
    }

    tbody {
      display: block;
      height: calc(100vh - 290px);
      overflow-y: auto;
      overflow-x: hidden;

      td:first-child {
        width: 28%;
        cursor: pointer;
      }

      td {
        outline: none;
        &.loans {
          color: #db2829;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
        .disabled {
          opacity: 0.3;
        }
        padding: 0.7em 0.7em 0.2em !important;
      }
    }

    .action-check {
      margin-right: 1rem;
      position: relative;
      top: 15px;
      display: inherit;
    }

    thead,
    tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed;
      position: relative;
    }
  }
}

.member-bottom-info {
  display: flex;
  align-items: 'center';

  .phone {
    margin-top: 3px;
  }
}

.member-name {
  margin-left: 30px;
}

.member-message {
  padding: 20px 20px 0;
}

tr:hover {
  background-color: #f8f8f8;
  cursor: auto !important;
}

.group-name {
  display: flex;
  align-items: center;

  &:focus {
    outline: 0;
  }

  .thumbnail {
    margin-right: 15px;
  }

  .up {
    display: flex;
  }

  .phone {
    color: #888;
    font-size: 0.85em;
    font-weight: 400;
  }
}

.group-overview {
  padding: 16px;

  .line-chart__container {
    h3 {
      margin-bottom: 55px;
    }
  }

  .line-chart__container,
  .barchart__container {
    width: 100%;
    margin: 0px !important;
  }

  .barchart__container {
    height: 252px !important;
  }

  .group-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    border: 1px solid #d8d8d8;
    border-radius: 5px;
    height: calc(625px + 2rem);

    .info-wrapper {
      display: flex;
      flex: 1;
      flex-direction: column;

      & > * {
        width: 100%;
      }

      .header {
        display: flex !important;
        align-items: center !important;
        font-size: 1.2em;
      }

      .group-details {
        display: flex;
        justify-content: space-between;

        .details {
          margin-bottom: 10px;
          height: 475px;
          overflow-y: auto;
          padding-right: 1rem;

          .detail {
            padding-bottom: 20px;
          }

          .right-align {
            display: flex;
            justify-content: flex-end;
            padding-left: 15px;
            min-height: 39px;
          }

          .location {
            text-align: right;
            padding: 5px 0;
          }
        }
      }
    }

    .bottom {
      position: relative;
      margin-top: auto;

      .label {
        text-transform: capitalize;
        font-size: 0.9em;
        display: inline-block;
        padding: 5px 10px;
        background-color: #57d9a3;
        color: #fff;
        border-radius: 3px;
      }

      .content {
        font-size: 1em;
        text-align: right !important;
        padding-top: 10px;
        margin-top: 5px;
        border-top: solid 1px rgba(72, 78, 115, 0.1);
      }
    }
  }
}

.group-status {
  width: 90px;
  max-height: 22px;
  text-align: center;
  text-transform: capitalize;
  font-size: 0.8em;
  display: inline-block;
  padding: 2px;
  background-color: #57d9a3;
  color: #fff;
  border-radius: 3px;

  &.active {
    background-color: #57d9a3 !important;
  }

  &.inactive {
    background-color: #fc5739 !important;
  }
}

.group-status.member {
  position: initial;
  margin-left: 10px;
}

.delete-form {
  padding-bottom: 30px;

  &__reason {
    resize: none !important;
    height: 150px !important;
    margin: 20px 0 !important;
  }
}

#app #content #pages {
  .setting-dropdown {
    .icon.setting {
      font-size: 16px;
    }

    .menu {
      right: 0;
      left: unset !important;
      min-width: 150px;
      padding: 2px;

      .item {
        border-bottom: 1px solid #f0efef;

        &:last-child {
          border: none;
        }
      }
    }
  }
}

.member-check-box {
  margin-right: 20px !important;
}

.pin-form {
  margin-top: 15px;
}

.recompute_group_balance {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  // padding: 10px;
  &__main_title {
    color: #555;
    font-size: 13px;
    margin-top: 30px;
  }
}
.group_access_bank_balance_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__value {
    display: flex;
    width: 100%;
    flex: 1;
    img {
      margin-right: 20px;
    }
    &__container {
      p {
        margin: 0;
      }
      &__title {
        color: #222;
        font-size: 14px;
      }
      &__balance {
        color: #555;
        font-size: 18px;
      }
      &__date {
        color: #666;
        font-size: 12px;
      }
    }
  }
  &__loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &__main_title {
      color: #555;
      font-size: 13px;
      margin-top: 30px;
    }
  }
}
