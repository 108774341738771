.uploading-box {
  width: 100%;
  margin-top: 10px;
  padding: 10px 10px 0;
  border: 1px solid #ccc;
  border-radius: 8px;

  &__top > div {
    display: flex;
    width: 100%;
    flex-direction: row;
  }

  &__top {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-size: 0.9em;
    font-weight: 500;
    margin-bottom: 10px;
  }

  &__bar {
    width: 100%;
    height: 4px;
    margin-bottom: 20px;
    background-color: rgba(5, 114, 206, 0.2);
    width: 100%;
    overflow: hidden;

    &__value {
      width: 100%;
      height: 100%;
      background-color: rgb(5, 114, 206);
      animation: indeterminateAnimation 1s infinite linear;
      transform-origin: 0% 50%;
    }

    @keyframes indeterminateAnimation {
      0% {
        transform: translateX(0) scaleX(0);
      }

      40% {
        transform: translateX(0) scaleX(0.4);
      }

      100% {
        transform: translateX(100%) scaleX(0.5);
      }
    }
  }
}
