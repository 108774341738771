.pannels-column {
  overflow: auto;
  flex: 1;
}

.ui.pointing.secondary.menu {
  width: 100%;
  background: #fff;
}

.ui.pointing.secondary.menu + .save-dash-toolbar {
  margin-top: 16px;
  margin-left: 16px;
}

.scroll-wrapper {
  padding-top: 16px;
  padding-bottom: 16px;
  display: block;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  overflow-y: auto;
  & > .save-dash-toolbar {
    margin-right: -16px;
    margin-bottom: 16px;
  }
}
