.popup-content {
  &__link {
    background-color: transparent !important;
  }
  .approve-action-link {
    color: rgb(12, 233, 12);
  }

  .decline-action-link {
    color: red;
  }

  .amend-action-link {
    color: #4c9aff;
  }

  .modifications-link {
    color: #000;
  }
}
