.fetch_export_data_container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  &__get_data_button {
    align-self: flex-end;
  }
  &__forms_input_container {
    display: flex;
    &__export_date_input {
      flex: 1;
      margin: 5px;
    }
  }
  &__dropdown {
    margin: 10px 10px 0 10px;
    padding: 5px 5px 5px 0;
  }
}
