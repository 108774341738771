.toggle {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 1rem;
  padding: 0;
  .left {
    display: flex;
  }
  .right {
    .ui.disabled.checkbox label {
      cursor: not-allowed !important;
    }
  }
}
