@import '../../assets/styles/colors.scss';

.dash-toolbar ~ {
  display: none;
  width: 80% !important;
}

section.organization-users {
  .organization-user {
    display: flex;
    align-items: flex-start;
    // padding: 10px 0 0;
    margin-bottom: 5px;
    min-height: 60px;

    .email {
      display: inline-block;
      min-width: 280px;
      max-width: 280px;
      margin-right: 0 !important;
    }

    &:hover {
      background-color: #f8f8f8;
    }

    .thumbnail-icon:focus {
      outline: none;
    }

    .thumbnail {
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 20px;
      object-fit: cover;
      &:hover {
        cursor: pointer;
      }
    }

    .details {
      flex-grow: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 10px;
      border-bottom: solid 2px rgba(72, 78, 115, 0.1);

      .info {
        .name {
          font-size: 1.2em;
          padding-bottom: 5px;
        }

        .contact {
          color: #888;
          font-size: 0.9em;
          font-weight: 400;

          span {
            margin-right: 50px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: inline-block;
            &.phone_number {
              min-width: 150px;
              max-width: 150px;
            }
            &.user_id {
              min-width: 60px;
              max-width: 60px;
            }
            &.created_at {
              display: inline-block;
              min-width: 200px;
              max-width: 200px;
            }
            &.comission {
              display: inline-block;
              min-width: 130px;
              max-width: 130px;
            }
            &.cashout {
              color: #db2829;
              margin-right: 0;
            }
          }
        }
      }

      .roles {
        display: flex;

        .role {
          text-transform: capitalize;
          font-size: 0.9em;
          display: inline-block;
          padding: 5px 10px;
          background-color: #57d9a3;
          color: #fff;
          border-radius: 3px;
          margin-bottom: 10px;
          margin-right: 20px;
          background-color: $color-label-green;
          white-space: nowrap;
        }
      }
    }
  }
}
