.create-project {
  padding-left: 16px;
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .wrapper {
      width: 35%;
      min-width: 350px;

      .title {
        font-size: 1.4em;
        font-weight: 600;
        padding-bottom: 30px;
      }
    }
  }

  .areas-of-intervention {
    &__title {
      font-weight: 600;
    }
  }
}
