@import '../../../../assets/styles/colors.scss';

.barchart {
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 250px;
    border: 1px solid lighten($color-dark, 60%);
    padding: 10px;
    margin-left: 20px;
    border-radius: 4px;
    margin: 0 10px 10px;
  }

  &__heading {
    display: flex;
    justify-content: space-between;

    i {
      margin-top: 5px;
    }
  }

  &__title {
    font-size: 1.1em;
  }

  &__date {
    font-weight: 300;
    color: lighten($color-dark, 40%);
    margin-bottom: 10px;
  }

  &__count {
    display: block;
    margin: 15px 0 20px;
    font-size: 1.8em;
  }

  &__graph {
    margin: 10px 1% 0px;
    padding: 10px;
    border-radius: 4px;
    display: grid;
    place-content: center;
  }
}
