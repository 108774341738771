@import '../../../../assets/styles/colors.scss';

.profile-requests__lists {
  padding-left: 16px;
  .customized-title {
    a {
      color: map-get($i-color-list, royalBlue);
    }
  }

  .groups-table {
    padding: 16px 0 0;
    table {
      thead {
        th:first-child {
          width: unset;
        }
      }

      tbody {
        display: block;
        height: calc(100vh - 290px);
        overflow-y: auto;
        overflow-x: hidden;

        td {
          padding: 0.7rem 0 !important;
        }
      }
    }
  }
}
