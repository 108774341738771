@import '../../../../assets/styles/colors.scss';

.accordion__container,
.accordion__item {
  display: flex !important;
  justify-content: space-between;
  color: map-get($i-color-list, yaleBlue) !important;
  .accordion__label {
    font-weight: 700;
  }
}

.accordion__item {
  margin-left: 1.5rem;
  padding: 0 !important;
}
