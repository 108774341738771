@import '../../../assets/styles/colors.scss';

.list-profiles {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &.not-all {
    flex-grow: 0.6;
    margin-left: 16px;
  }
  &:not(.not-all) {
    .save-dash-toolbar {
      margin-left: 16px;
    }
  }
  &__items {
    padding-right: 16px;
    overflow: hidden;
    overflow-y: auto;

    &__item {
      padding: 10px 0 0;
      margin-bottom: 10px;

      display: grid;
      grid-template-columns: 60px 1fr;

      &:hover {
        cursor: pointer;
        background-color: #f8f8f8;
      }

      .thumbnail {
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 20px;
        object-fit: cover;
      }

      &__details {
        flex-grow: 1;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 10px;
        border-bottom: solid 2px rgba(72, 78, 115, 0.1);

        &__info {
          &__name {
            font-size: 1.2em;
            padding-bottom: 5px;
            font-weight: 400;
          }

          &__numbers {
            color: #888;
            font-size: 0.9em;
            font-weight: 400;
            width: 100%;
            line-height: 1.5;
            display: grid;
            grid-template-columns: 34% 34% 16% 16%;

            .number-group {
              margin-right: 50px;
              display: flex;
              align-items: flex-start;

              &__icon {
                margin: 0 5px !important;
              }
            }
          }
        }
      }
    }
  }
}
