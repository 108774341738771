section.activity {
  display: flex;
  flex: 1;
  flex-direction: row;
  overflow: hidden;
  .list-organizations, .list-projects{
    flex: 1;
    flex-grow: inherit;
  }
}
