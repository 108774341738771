.info__card {
  display: flex;
  align-items: center;
  background-color: white;
  color: black;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1),
    0 6px 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin: 0 5px;
  font-size: 15px;
  padding: 5px 18px;

  .amount-info-card__section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__content {
    max-width: 245px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .badge-div {
    padding: 0;
    color: white;
    margin-left: 10px;
  }
}
