@import '../../assets/styles/colors.scss';

.ResetPassword {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: $color-primary;

  .container {
    width: 30vw;
    max-width: 500px;
    min-width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img {
      width: 38%;
      margin-bottom: 40px;
    }

    form {
      align-self: stretch;
      padding: 15px;
      background-color: #fff;
      border-radius: 5px;

      h1 {
        text-align: center;
      }

      .field {
        label {
          font-weight: 300;
        }
        &.reset-phone-input {
          .label {
            line-height: 36px !important;
          }
        }
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        appearance: none;
        margin: 0;
      }

      input[type='number'] {
        -moz-appearance: textfield;
      }

      .success-message,
      .error-message {
        font-weight: 300;
      }
    }

    .login {
      align-self: stretch;
      margin-top: 15px;

      a {
        color: #fff;
        font-weight: 300;
      }
    }
  }
}
