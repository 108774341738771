.attachment {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__icon {
    background-image: url('../../../assets/images/paper-clip.svg');
    height: 18px;
    width: 18px;
    border: none;
    background-color: transparent;

    &:hover {
      cursor: pointer;
    }
  }
}

.required-label::after {
  content: '*';
  margin-left: 5px;
  color: red;
}

.attach-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1px 0 1px 4px;
  margin-bottom: 10px;
  background: rgba(33, 133, 208, 0.1);
  border-radius: 4px;

  .attach-item--button {
    background: transparent;
  }
}
