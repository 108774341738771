.SingleUser__body__details {
  .card {
    min-width: 400px !important;

    .title {
      color: #777 !important;
      font-size: 1.2em !important;
    }

    a {
      font-size: 1.1em !important;
      color: #2285d1 !important;
    }
  }
}

.not-applicable {
  display: block;
  margin-left: 25px;
}

.confirm {
  margin-top: 20px !important;
}
