.wallet {
  &__transactions {
    overflow: none;
    width: 98%;
    margin-top: 20px;

    &--table-body {
      overflow: auto;
    }

    thead,
    tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }

    tbody {
      display: block;
      height: calc(100vh - 480px);
      overflow: scroll;
    }

    .lg-tbody {
      height: calc(100vh - 220px);
    }

    &--originator {
      display: flex;
      align-items: center;

      .thumbnail {
        width: 30px;
        height: 30px;
        min-width: 30px;
        min-height: 30px;
        font-size: 0.9em;
        margin-right: 10px !important;
      }
    }

    &--status {
      display: flex;
      align-items: center;
      border-top: none !important;

      &--buble {
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 8px;
      }

      &--icon {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        color: #fff;
        font-size: 0.8em;
        margin-left: 20px;
        background-color: #4d99ff;
      }
    }

    .status-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 23px;
      height: 23px;
      border-radius: 50%;
      color: #fff;
      font-size: 1.1em;
      margin-left: 20px;
      background-color: #4d99ff;
      margin-right: 0 !important;
      outline: none !important;

      span {
        margin-top: 2px;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.popup-content {
  display: flex;
  flex-direction: column;
  background-color: #fff;

  &__link {
    color: #4d99ff;
    padding-bottom: 3px;
    margin-bottom: 5px;
    border-bottom: 1px solid #f0f0f0;

    &:hover {
      cursor: pointer;
    }
  }

  &__description {
    color: #111;
    font-weight: 300;
  }
}

.transaction-popup {
  background-color: #fff !important;
}
