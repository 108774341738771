@import '../../../../assets/styles/colors.scss';

.profile-group__lists {
  padding-right: 16px;
  padding-left: 16px;

  .customized-title {
    a {
      color: map-get($i-color-list, royalBlue);
    }
  }
}
