.expandable-row {
  display: flex;
  flex-direction: row;
  .state {
    color: #888;
    margin-top: 0.4rem;
    cursor: pointer;
    &::before {
      content: '';
      width: 0.6em;
      height: 0.6em;
      display: inline-block;
      border-right: 0.23em solid currentColor;
      border-bottom: 0.23em solid currentColor;
      transform: rotate(-45deg);
    }
    &.open ~ .items {
      max-height: 100%;
    }
    &.open::before {
      content: '';
      width: 0.6em;
      height: 0.6em;
      display: inline-block;
      border-right: 0.23em solid currentColor;
      border-bottom: 0.23em solid currentColor;
      transform: rotate(45deg);
    }
    &:focus {
      outline: none;
    }
  }
  .items {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    overflow: hidden;
    max-height: 30px;
    .item {
      align-content: center;
      flex-grow: 0;
      width: 33%;
      height: 30px;
      color: #888;
      font-size: 0.9em;
      font-weight: 400;
      b,
      img {
        margin-right: 0.3rem;
      }
      i {
        margin-top: -0.3rem;
      }
    }
  }
}
