.filter_options {
  display: flex;
  align-items: center;
  border: none;
  margin: 0 10px 0 0;
  &__title {
    margin: 0px 10px;
    font-size: 13px;
    font-weight: bold;
  }
}
