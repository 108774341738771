@import '../../../assets/styles/colors.scss';

.changepin {
  &__container {
    margin-left: 10%;
    margin-right: 10%;
  }

  &__submit {
    text-align: right;
  }
}
