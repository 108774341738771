.group-transactions__lists {
  padding-top: 20px;
  padding: 16px 16px 2px;
  .groups-transactions-table {
    padding: 16px 16px 2px;

    table {
      thead {
        th:first-child {
          width: 22%;

          span {
            display: block;
            margin-left: 2rem;
          }
        }
        .action-check {
          top: 3px;
        }
      }

      tbody {
        display: block;
        height: calc(100vh - 290px);
        overflow-y: auto;
        overflow-x: hidden;

        td:first-child {
          width: 22%;
          cursor: pointer;
        }
        td:nth-child(9) {
          width: 7%;
        }
        td:last-child {
          width: 3%;
        }
      }

      thead,
      tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
        position: relative;
      }
    }
  }

  .reason-popup-bold {
    font-weight: bolder !important;
    font-size: 29px;
  }

  .action-icon:hover {
    cursor: pointer;
  }

  .view-reason {
    color: #4c9aff;
  }

  .view-reason:hover {
    cursor: pointer;
  }

  .request-status {
    font-size: 12px;
    margin-bottom: 12px;
    display: flex;
  }
}
