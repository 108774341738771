.overview {
  &__grid {
    &--first {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

.agents-table {
  padding: 16px;

  table {
    thead {
      th:first-child {
        width: 24%;
      }
    }

    tbody {
      display: block;
      height: calc(100vh - 275px);
      overflow: auto;

      td:first-child {
        width: 24%;
      }
    }

    thead,
    tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }
  }
}

.agent-name {
  display: flex;
  align-items: center;

  .thumbnail {
    margin-right: 15px;
  }
}

.project-list-title {
  font-size: 1em;
  padding: 5px 20px;
}

.right-component {
  .ui.fluid.selection.dropdown {
    padding-top: 10px !important;
    padding-bottom: 0px !important;
    min-height: 25px !important;
    margin-right: 20px;

    i {
      padding-top: 10px !important;
      padding-bottom: 0px !important;
    }
  }
}

.sidebar {
  .ui.fluid.selection.dropdown {
    padding-bottom: 10px !important;
  }
}
