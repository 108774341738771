@import '../../../assets/styles/colors.scss';

.billings {
  padding-top: 16px;
  table {
    tbody {
      display: block;
      height: 75vh;
      overflow: auto;
    }
    thead,
    tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }
  }

  &--status {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-left: 16px;
  }

  &-info {
    width: 451px;
    height: 561px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &__title {
      font-size: 24px;
      font-weight: 700;
      text-align: center;
      margin-top: 4px;
      color: map-get($i-color-list, yaleBlue);
    }
    &__details {
      display: flex;
      &--left,
      &--right {
        width: 220px;
      }
    }
    .divider {
      width: 450px;
    }
  }

  &__add,
  &__edit {
    margin-bottom: 48px;
    form {
      margin-top: 16px;
      .group-items {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .label-from {
          margin-left: 8px;
        }
      }

      .message-label-group {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}
