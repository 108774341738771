.agent-overview__message {
  margin: 100px 20px 0 !important;
  padding-top: 50px;
}

.agent-projects-list {
  min-width: 200px !important;
}

button.button {
  white-space: nowrap;
}
