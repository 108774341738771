.input_field {
  border: 2.66016px dashed rgba(50, 54, 82, 0.4);
  box-sizing: border-box;
  border-radius: 8.86719px;
  padding: 16px;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 244px;
  text-align: center;
  cursor: pointer;

  img.file {
    padding: 16px;
    width: 90px;
    height: 90px;
  }
  .format {
    padding-top: 16px;
    color: #32365299;
    .file_icons {
      padding-top: 12px;

      img {
        width: 30px;
        height: 30px;
      }
    }
  }
}
.dropzone-label {
  margin-bottom: 16px;
}
.uploaded_field {
  border-radius: 8.86719px;
  display: flex;
  flex-direction: column;
  height: 244px;
  cursor: pointer;

  &__file {
    background: rgb(34, 33, 33) !important;
    position: relative;
    width: 380px;
    height: 255px;
    overflow: hidden;
    display: flex;
    justify-content: center;

    &::before {
      content: 'change image';
      color: white;
      background: none;
      position: absolute;
      top: 50%;
      text-align: center;
      font-weight: 700;
    }
    &--image {
      object-fit: cover;
      width: inherit;
      height: inherit;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.5),
        rgba(0, 0, 0, 0.5)
      );
      opacity: 0.6;
    }

    &--name {
      padding: 1rem;
      background: rgba(33, 133, 208, 0.1);
      border-radius: 5px;
      display: flex;
      justify-content: space-between;
      .icon {
        cursor: pointer;
      }
    }
  }
  span {
    text-align: start;
    font-weight: 700;
    padding-top: 1rem;
  }
  &__delete {
    width: 88px;
    height: 32px;
    padding-bottom: 20px;
    align-items: flex-end;
  }
}
