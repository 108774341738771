.member-overview {
  .cycle {
    .content {
      min-height: 199px;
    }
  }
}

.input {
  margin-bottom: 0.3rem;
}

.header {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}
